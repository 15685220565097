import moment from 'moment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateService } from './date.service'; // Import your DateService

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    private dateService: DateService // Inject DateService here
  ) {

    super(dateLocale);
    console.log("jconstructor",this.locale);
  }

  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (value && typeof value === 'string') {

      const parsedMoment = this.dateService.moment(moment(value, parseFormat, this.locale, true));
      console.log('Parsed Moment:', parsedMoment);
      return parsedMoment;
    }
    return value ? this.dateService.moment(moment(value).tz(this.dateService.getBrowserTimezone())) : null;
  }

  format(date: moment.Moment, displayFormat: string): string {
    // Use DateService for consistent timezone handling during formatting
    return this.dateService.moment(date).format(displayFormat);
  }
}
